<script lang="ts">
  import * as svelte from "svelte";
  import favicon from "#/libdb.so/site/assets/favicon.ico?url";
  import normalizeCSS from "normalize.css/normalize.css?url";
  import {
    toggleView,
    focusedView,
    viewWindows,
    toggleShowDesktop,
  } from "#/libdb.so/site/lib/views.js";
  import { onekoCursor, dragWindows, nsfw, theme, prefersDark } from "#/libdb.so/site/lib/prefs.js";

  import SystemUsersIcon from "#/libdb.so/site/assets/papirus/system-users.svg?url";
  import TerminalIcon from "#/libdb.so/site/assets/papirus/terminal.svg?url";

  import Oneko from "#/libdb.so/site/components/Oneko/oneko.svelte";
  import Switch from "#/libdb.so/site/components/Switch.svelte";
  import Terminal from "#/libdb.so/site/components/Terminal/index.svelte";
  import Portfolio from "#/libdb.so/site/components/Portfolio/index.svelte";
  import PopoverButton from "#/libdb.so/site/components/PopoverButton.svelte";
  import AccessibilityIcon from "#/libdb.so/site/components/Papirus/preferences-desktop-accessibility-symbolic.svg?raw";

  let currentTime = "00:00";
  function updateTime() {
    currentTime = new Date().toLocaleTimeString(undefined, {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
  updateTime();

  const updateTimer = setInterval(() => updateTime(), 5000);
  svelte.onDestroy(() => clearInterval(updateTimer));

  const fonts = [
    "Inconsolata", //
    "Lato",
    "Nunito",
    "Source Code Pro",
  ];

  let navbarWidth = 0;
  let navbarHeight = 0;

  let screenWidth = 0;
  let screenHeight = 0;

  $: activeWindow = $viewWindows[$focusedView!] || null;
</script>

<svelte:head>
  <link rel="stylesheet" href={normalizeCSS} />
  {#each fonts as font}
    <link
      rel="stylesheet"
      href={"https://fonts.googleapis.com/css2?family=" +
        font.replaceAll(" ", "+") +
        ":wght@400;500;600;700;900&display=swap"}
    />
  {/each}
  <meta name="description" content="Hi, I'm Diamond!" />
  <meta name="author" content="diamondburned" />
</svelte:head>

<div
  class="screen"
  data-theme={$theme}
  data-prefers-dark={$prefersDark}
  bind:clientWidth={screenWidth}
  bind:clientHeight={screenHeight}
>
  <div class="backdrop" />

  {#if $onekoCursor}
    <Oneko
      windows={[
        activeWindow,
        {
          x: screenWidth - navbarWidth,
          y: screenHeight - navbarHeight,
          width: navbarWidth,
          height: navbarHeight,
        },
      ].filter((w) => w != null)}
    />
  {/if}

  <div class="content">
    <Terminal />
    <Portfolio />
  </div>

  <nav id="navbar" bind:clientWidth={navbarWidth} bind:clientHeight={navbarHeight}>
    <div class="left">
      <button class="start" on:click={() => alert("hii!!")}>
        <img src={favicon} alt="diamondburned's eye" />
      </button>
      <div class="window-list">
        <button class:active={$focusedView == "portfolio"} on:click={() => toggleView("portfolio")}>
          <img src={SystemUsersIcon} alt="Portfolio icon" />
          About
        </button>
        <button class:active={$focusedView == "terminal"} on:click={() => toggleView("terminal")}>
          <img src={TerminalIcon} alt="Terminal icon" />
          xterm.js
        </button>
      </div>
    </div>
    <div class="right">
      <PopoverButton class="icon toggle-features" title="Toggle Features">
        {@html AccessibilityIcon}
        <div slot="popover" class="preferences">
          <h4>Preferences</h4>
          <Switch bind:checked={$onekoCursor}>Oneko.js</Switch>
          <Switch bind:checked={$dragWindows}>Drag Windows</Switch>
          {#if $nsfw}
            <Switch bind:checked={$nsfw}>
              <span class="text-pink-glow">NSFW Mode</span>
            </Switch>
          {/if}
          <hr />
          <small>Try the <code>vars</code> command too!</small>
        </div>
      </PopoverButton>
      <span class="clock">{currentTime}</span>
      <button class="view-desktop" on:click={() => toggleShowDesktop()} />
    </div>
  </nav>
</div>

<style global lang="scss">
  .text-pink {
    color: var(--pink);
  }

  .text-blue {
    color: var(--blue);
  }

  .text-pink-glow {
    @extend .text-pink;
    text-shadow: var(--pink-glow);
  }

  .text-blue-glow {
    @extend .text-blue;
    text-shadow: var(--blue-glow);
  }

  html,
  body {
    height: 100%;
  }

  body {
    font-family: "Lato", "Source Sans Pro", "Noto Sans", "Helvetica", "Segoe UI", sans-serif;
    font-size: clamp(0.95rem, 0.8429rem + 0.3429vw, 1.1rem);
  }

  div.screen {
    width: 100vw;
    height: 100%;

    /* https://www.joshwcomeau.com/gradient-generator?colors=f690dc|4e98fa&angle=55&colorMode=hcl&precision=20&easingCurve=0.25|0.75|0.75|0.25 */
    background-color: dimgray;
    background-image: url("/_fs/pics/background.jpg");
    background-size: cover;

    display: flex;
    flex-direction: column;

    & > .content {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    & > * {
      z-index: 1;
    }

    & > .backdrop {
      width: 100%;
      height: 100%;
      z-index: 0;
      position: absolute;
      backdrop-filter: blur(20px);
    }
  }

  #navbar {
    background-color: var(--adw-headerbar-bg-color);
    color: var(--adw-headerbar-fg-color);

    display: flex;
    justify-content: space-between;
    gap: 0.5rem;

    font-weight: 700;
    user-select: none;

    .left {
      flex: 1;
    }

    .left,
    .right {
      display: flex;
      flex-direction: row;
    }

    .window-list {
      --button-width: clamp(125px, 20%, 200px);

      width: 100%;

      display: grid;
      grid-auto-flow: column;
      grid-template-columns: repeat(auto-fit, var(--button-width));
      grid-template-rows: 1fr;

      overflow: auto;
    }

    button {
      border: none;
      font-weight: inherit;
      font-family: inherit;
      font-size: 0.9em;
      background-color: var(--adw-headerbar-bg-color);

      display: flex;
      align-items: center;

      padding: 0.35em 0.65em;

      border-top: 2px solid transparent;
      border-bottom: 2px solid transparent;

      &:hover {
        background-color: var(--adw-button-hover-color);
      }

      &.active {
        background-color: var(--adw-button-active-color);
        border-bottom: 2px solid var(--adw-accent-color);

        &:hover {
          background-color: var(--adw-button-checked-hover-color);
        }
      }

      &:disabled {
        opacity: 0.5;
      }

      img,
      :global(svg) {
        width: 1.5em;
        height: 1.5em;
      }
    }

    .window-list button {
      min-width: var(--button-width);
      padding-left: 0;

      img {
        margin: 0 0.5em;
      }
    }

    .right button {
      img,
      :global(svg) {
        width: 1.25em;
        height: 1.25em;
      }
    }

    .preferences {
      display: flex;
      flex-direction: column;
      padding: 0.5em;
      gap: 0.5em;

      h4 {
        margin: 0;
        margin-bottom: 0.25em;
      }

      label {
        width: 100%;
        display: flex;
      }

      hr {
        width: 100%;
        border: none;
        border-top: 1px solid var(--adw-card-shade-color);
      }

      small {
        opacity: 0.5;
        font-size: 0.85em;
      }
    }

    .toggle-nsfw {
      :global(svg) {
        --color: var(--pink-rgb);

        fill: rgb(var(--color));
        width: 1.5em;
        height: 1.5em;
        filter: drop-shadow(0 0 var(--glow-radius) rgba(var(--color), var(--glow-alpha)));
        opacity: 0.75;
      }

      &:hover :global(svg) {
        --color: var(--blue-rgb);
        opacity: 1;
      }
    }

    .clock {
      height: 100%;
      display: flex;
      align-items: center;
      padding: 0 0.5rem;
    }

    .view-desktop {
      padding: 0;
      padding-left: 0.5em;
      border-left: 1px solid var(--adw-card-shade-color);

      &:hover {
        background-color: var(--bg-hover);
      }
    }
  }
</style>
